<template>
  <div class="loading">
    <span uk-spinner="ratio: 4.5"></span>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variable.scss';

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
</style>
